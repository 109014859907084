import request from '../utils/request';
import UrlUtil from '../utils/url-config';

const API = UrlUtil.getAPI();
//商品模块接口
export const ShopFetch = {
    //获取商品首页广告图
    searchBannerList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchBannerList`, params, success);
    },
    //记录当前广告的点击次数
    updateBannerClick: (params, success) => {
        return request('POST', `${API}/v1/shop/home/noAuth/changeClickNum/${params}`, params, success)
    },
    //获取商品首页广告图
    searchIndexGoodsList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchIndexGoodsList`, params, success)
    },
    //分页获取首页公告列表
    searchNoticeList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchNoticeList`, params, success)
    },
    //获取公告详情
    getNoticeById: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getNoticeById/${params}`, params, success)
    },
    //获取商品详情
    getGoodsById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsById/` + params, params, success)
    },
    //添加购物车时获取商品详情
    getCartGoodsById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/queryGoodsDetailById/` + params, params, success)
    },
    //获取商品详情--新
    getActivityMapForGoods: (params, success) => {
        return request('GET', `${API}/v1/saleActivity/promotionActivity/getActivityMapForGoods`, params, success)
    },
    //获取商品详情
    getGoodsByIdAndClientIdForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsByIdAndClientIdForShop`, params, success)
    },
    //查询出替换oe统计信息
    getOeListByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/accessories/goodsOe/getListByGoodsId/${params.goods_id_}/0`, success)
    },
    // 根据产品条形码获取客户产品信息
    getGoodsByBarcodeAndClientIdForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/getGoodsByBarcodeAndClientIdForShop`, params, success)
    },
    //商城商品分页查询 
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchListForShop`, params, success)
    },
    //换购 商城商品分页查询  
    getDiscountActGoodsList: (params, success) => {
        return request('GET', `${API}/v1/shop/pisen/cart/getDiscountActGoodsList`, params, success)
    },
    //web端查询收藏商品
    searchFavoriteGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchFavoriteGoodsForWeb`, params, success)
    },
    //web端收藏商品分类筛选
    searchTypeByFavorite: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByFavorite`, params, success)
    },
    //web端查询常购商品清单
    searchClientGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchClientGoodsForWeb`, params, success)
    },
    //web端常购商品分类筛选
    searchTypeByClientGoods: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByClientGoods`, params, success)
    },
    //获取指定公司的商品分类树
    getUsePropertyTree: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getUsePropertyTree`, params, success)
    },
    //商城商品分页查询
    searchGoods: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoods`, params, success)
    },
    //我收藏的商品分页查询
    searchFavoriteListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goodsFavorite/noAuth/searchFavoriteListForShop`, params, success)
    },
    //查询商品被收藏数量
    countFavorites: (params, success) => {
        return request('GET', `${API}/v1/shop/goodsFavorite/noAuth/countFavorites/${params}`, params, success)
    },
    //商城商品分页查询
    checkPhone: (params, success) => {
        return request('GET', `${API}/api/v1/account/checkPhone`, params, success)
    },
    addGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/addGoodsFavorite`, params, success)
    },
    deleteGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/deleteGoodsFavorite`, params, success)
    },
    batchDeleteGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchDeleteGoodsFavorite`, params, success)
    },
    //列出企业所有商品风格
    listStyleByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listStyleByCompany`, params, success)
    },
    //列出企业所有商品品牌
    listBrandByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listBrandByCompany`, params, success)
    },
    //列出企业所有商品分类
    listTypeByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getTypeTree`, params, success)
    },
    //列出企业所有商品标签
    searchTagList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listTagByCompany`, params, success)
    },
    //获取商品设置信息
    getGoodsSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/goodsSetting/getByCompanyId/${params}`, params, success)
    },
    //获取销售设置信息
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting/${params}`, params, success)
    },
    //获取销售设置信息
    getShopSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getShopSetting/${params}`, params, success)
    },
    //获取商城开放注册应用设置
    getShopRegisterSetting: (params, success) => {
        return request('GET', `${API}/v1/shopRegisterSetting/noAuth/getByCompany`, params, success)
    },
    //获取仓库设置
    getHouseSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getHouseSetting/${params}`, params, success)
    },
    //获取企业信息
    getCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getCompany/${params}`, params, success)
    },
    // 获取客户分类列表
    findList: (params, success) => {
        return request('GET', `${API}/v1/client/clientType/findList`, params, success)
    },
    // 获取装修信息
    getPageDataByPc: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/getPageDataByPc`, params, success)
    },
    // 获取装修信息
    getPcTemplateById: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/getPcTemplateById/${params}`, params, success)
    },
    // 获取店铺装修商品
    searchListForDecorate: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/searchListForDecorate`, params, success)
    },
    // 获取店铺装修商品
    countDecorateVisitor: (params, success) => {
        return request('POST', `${API}/v1/decorate/wpShopTemplate/noAuth/countVisitor/${params}`, params, success)
    },
    // 获取店铺装修促销
    listForGoodsPromotionActivityIds: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/listForGoodsPromotionActivityIds`, params, success)
    },
    //查询指定商品当前应用的促销
    goodsPromotionByGoods: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/listByGoods/${params}`, params, success)
    },
    //查询促销列表
    searchListActivityForShop: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/noAuth/searchListActivityForShop`, params, success)
    },
    //获取运费计算设置
    getShipFeeSettingByLogin: (params, success) => {
        return request('GET', `${API}/v1/setting/shipFeeSetting/getByLoginUser`, params, success)
    },
    //获取客户优惠券
    listCouponByShop: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/listCouponByShop`, params, success)
    },
    //获取客户相关优惠券活动
    listCouponActivityByShop: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/listCouponActivityByShop`, params, success)
    },
    //领取优惠券
    receiveCoupon: (params, success) => {
        return request('POST', `${API}/v1/shop/coupon/receiveCoupon`, params, success)
    },
    //批量领取优惠券
    batchReceiveCoupon: (params, success) => {
        return request('POST', `${API}/v1/shop/coupon/batchReceiveCoupon`, params, success)
    },
    //获取账户明细页面统计
    getClientCapital: (params, success) => {
        return request('GET', `${API}/v1/capital/capitalAccount/getClientCapital`, params, success)
    },
    //账户明细
    searchLogList: (params, success) => {
        return request('GET', `${API}/v1/fn/capitalRecord/searchLogList`, params, success)
    },
    // 客户资金流水
    searchCapitalRecordListByClient: (params, success) => {
        return request('GET', `${API}/v1/fn/capitalRecord/searchListByClient`, params, success)
    },
    // 客户资金流水
    searchLisCensustByClient: (params, success) => {
        return request('GET', `${API}/v1/fn/capitalRecord/searchLisCensustByClient`, params, success)
    },
    //获取预存款明细
    searchFinanceDetail: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchFinanceDetail`, params, success)
    },
    //记录浏览商品足迹
    saveGoodsViewLog: (params, success) => {
        return request('POST', `${API}/v1/goods/goodsViewLog/save`, params, success)
    },
    //获取浏览商品足迹
    searchViewLogListForShop: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsViewLog/searchViewLogListForShop`, params, success)
    },
    //刪除足迹
    deleteViewLogListByIds: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsViewLog/deleteByIds/${params}`, params, success)
    },
    //获取推荐商品
    searchRecommendedGoodsListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchRecommendedGoodsListForShop`, params, success)
    },
    //获取商品套餐列表
    searchGoodsPackageListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoodsPackageListForShop`, params, success)
    },
    //获取商品套餐详情
    getGoodsPackageByIdForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsPackageByIdForWeb/${params}`, params, success)
    },
    // 查询品牌
    searchBrandList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listBrandByCompany`, params, success)
    },
    // 根据分类查询商品热词
    searchGoodsCustomKeywordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoodsCustomKeywordList`, params, success)
    },
    // 查询指定分支机构
    getSubsidiaryById: (params, success) => {
        return request('GET', `${API}/v1/shop/subsidiary/getById/${params}`, params, success)
    },
    // 获取汽车品牌管理表列表
    accessoriesTypeSearchBrandList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesType/searchBrandList`, params, success)
    },
    //检查是否存在商品图片
    checkGoodsImage: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/checkGoodsImage`, params, success)
    },
    //检查是否有查看商品详情的权限
    ownSpuList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/ownSpuList`, params, success)
    },

};
//购物车模块接口
export const CartFetch = {
    // 导出
    exportCartList: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/exportCartList`, params, success)
    },
    // 保存/修改商城购物车列表
    batchModify: (params, success) => {
        return request('BODY', `${API}/v1/shop/cart/updateForWeb`, params, success)
    },
    searchList: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchList`, params, success)
    },
    chooseCart: (params, success) => {
        return request('BODY', `${API}/v1/shop/pisen/cart/chooseByCartId`, params, success)
    },
    // 换购商品的更新购物车数量接口
    updateCartDiscount: (params, success) => {
        return request('BODY', `${API}/v1/shop/pisen/cart/updateCartDiscount`, params, success)
    },
    // 获取换购商品购物车数量
    getCartDiscountActQty: (params, success) => {
        return request('GET', `${API}/v1/shop/pisen/cart/getCartDiscountActQty`, params, success)
    },
    //换购 获取订单生成列表  
    getCartListOrder: (params, success) => {
        return request('GET', `${API}/v1/shop/pisen/order/getCartListForCreateOrder`, params, success)
    },
    // 生成订单
    createOrderList: (params, success) => {
        return request('body', `${API}/v1/shop/pisen/order/createOrder`, params, success)
    },
    //保存/修改商城购物车列表
    saveCart: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/updateForShop`, params, success)
    },
    //webapp再来一单添加商品合并到购物车
    mergeCartForWeb: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/mergeCartForWeb`, params, success)
    },
    //修改商城购物车列表
    updateCart: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/updateForWeb`, params, success)
    },
    //购物车查询
    searchListForShopCart: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchListForShopCart`, params, success)
    },
    //立即购买
    updateCartByProductList: (params, success) => {
        return request('BODY', `${API}/v1/shop/pisen/cart/updateCartByProductList`, params, success)
    },
    //购物车查询
    newSearchListForShopCart: (params, success) => {
        return request('GET', `${API}/v1/shop/pisen/cart/getCartList`, params, success)
    },
    //修改商城购物车数量
    modifyCartNum: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/modifyCartNum`, params, success)
    },
    //更新购物车数量
    updateCartNum: (params, success) => {
        return request('body', `${API}/v1/shop/pisen/cart/updateCart`, params, success)
    },
    //删除商城购物车
    batchDelete: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/batchDelete`, params, success)
    },
    //删除商城购物车
    deleteByCartId: (params, success) => {
        return request('body', `${API}/v1/shop/pisen/cart/deleteByCartId`, params, success)
    },
    deleteByCartIds: (params, success) => {
        return request('body', `${API}/v1/shop/pisen/cart/deleteByCartIds`, params, success)
    },
    //列出企业配置的配送方式
    listShipWay: (params, success) => {
        return request('GET', `${API}/v1/shop/order/noAuth/listShipWay`, params, success)
    },
    //获取送货地址
    listAddress: (params, success) => {
        return request('GET', `${API}/v1/shop/address/listAll`, params, success)
    },
    //获取送货地址--代客下单
    clientAddress: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/listByClientId/${params}`, params, success)
    },
    //获取发票类型
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting/` + params, params, success)
    },
    //保存订单
    save: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/save`, params, success)
    },
    //保存预售订单
    // presaleSave: (params, success) => {
    //     return request('POST', `${API}/v1/shop/order/presaleSave`, params, success)
    // },
    //获取商品优惠信息
    getPromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByGoodsId`, params, success)
    },
    //根据规格ids串获取商品优惠信息
    getPromotionByProductIds: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByProductIds`, params, success)
    },
    //获取商品优惠信息
    getPromotionByProductId: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByProductId`, params, success)
    },
    //获取商品优惠信息
    getActivityById: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getActivityById/${params}`, null, success)
    },
    //获取订单优惠信息
    getPromotionByCompanyId: (params, success) => {
        return request('GET', `${API}/v1/sale/orderPromotion/getPromotionByCompanyId`, params, success)
    },
    //获取购物车SKU总数量
    sumNumber: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/sumNumber`, params, success)
    },
    //清除购物车下架商品
    cleanDownawayGoods: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/cleanDownawayGoods`, params, success)
    },
    //批量添加商城商品收藏
    batchAddGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchAddGoodsFavorite`, params, success)
    },
    //合并订单
    combinedPaySave: (params, success) => {
        return request('BODY', `${API}/v1/finance/combinedPay/save`, params, success)
    },
    //合并订单
    combinedPayGetBySaleOrderIds: (params, success) => {
        return request('GET', `${API}/v1/finance/combinedPay/getBySaleOrderIds/${params}`, params, success)
    },
    //合并订单
    combinedPayGetById: (params, success) => {
        return request('GET', `${API}/v1/finance/combinedPay/getById/${params}`, params, success)
    },
    //获取优惠券活动
    getCouponActivityById: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/getCouponActivityById/${params}`, params, success)
    },
    //获取商品预售信息
    // getpresalePromotionByGoodsId: (params, success) => {
    //     return request('GET', `${API}/v1/sale/presalePromotion/getPromotionByGoodsId`, params, success)
    // },
    // //获取商品预售信息
    // getPresalePromotionByProductIds: (params, success) => {
    //     return request('GET', `${API}/v1/sale/presalePromotion/getPromotionByProductIds`, params, success)
    // },
    // //获取预售活动信息
    // getPresalePromotionById: (params, success) => {
    //     return request('GET', `${API}/v1/sale/presalePromotion/getActivityById/${params}`, params, success)
    // },
    //购物车商品缓存添加
    batchSaveCartCache: (params, success) => {
        return request('POST', `${API}/v1/goods/goodsToCartCache/batchSave`, params, success)
    },
    //购物车商品缓存
    searchListForShopCartCache: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsToCartCache/searchListForShop`, params, success)
    },
    //购物车商品缓存
    deleteByEditCartCache: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsToCartCache/deleteByEdit`, params, success)
    },
    //购物车商品缓存
    cleanByclientIdCartCache: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsToCartCache/cleanByclientId/${params}`, params, success)
    },
    //计算购物车配送相关
    batchCalDistanceWithHouse: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/batchCalDistanceWithHouse`, params, success)
    },
    //批量导入购物车 模块
    batchImportCart: (params, success) => {
        return request('BODY', `${API}/v1/shop/cart/batchImportCart2`, params, success)
    },
};
//商城订单 
export const OrderFetch = {

    //查询关联订单号
    queryMixActOrderCodes: (params, success) => {
        return request('GET', `${API}/v1/shop/order/queryMixActOrderCodes`, params, success)
    },

    //保存/修改商城购物车列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getById/` + params, params, success)
    },
    // 获取订单详情
    getSaleOrderById: (params, success) => {
        return request('GET', `${API}/v1/sale/saleOrder/getById/${params}`, params, success)
    },
    //订单支付(线下或预付款)
    orderPay: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/orderPayByOfflineOrPre`, params, success)
    },
    //订单支付(线下或预付款)
    getPreBalance: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getPreBalance`, params, success)
    },
    //
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchListForShop`, params, success)
    },
    //取消订单
    orderCancel: (params, success) => {
        return request('POST', `${API}/v1/shop/order/cancel`, params, success)
    },
    //删除订单
    deleteOrderById: (params, success) => {
        return request('GET', `${API}/v1/shop/order/deleteById/${params}`, params, success)
    },
    //删除订单
    markNotViewForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/order/markNotViewForShop/${params}`, params, success)
    },
    //确认收货
    receiveBySaleOrder: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/receiveBySaleOrder`, params, success)
    },
    //确认收货
    receiveById: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/order/batchReceive/${_params}`, _params, _success, _error)
    },
    //确认收货
    receive: (params, success) => {
        return request('POST', `${API}/v1/shop/order/receive`, params, success)
    },
    // 根据快递单号获取物流信息
    getLogisticInfo: (params, success) => {
        return request('GET', `${API}/v1/common/helper/getLogisticInfo`, params, success)
    },
    orderPayByOfflineOrPre: (params, success) => {
        return request('POST', `${API}/v1/shop/order/orderPayByOfflineOrPre`, params, success);
    },
    // 分页获取商城订单消息列表
    searchOrderMessage: (params, success) => {
        return request('GET', `${API}/v1/shop/orderMessage/searchListByLogin`, params, success)
    },
    // 标记订单消息为已读
    markRead: (params, success) => {
        return request('POST', `${API}/v1/shop/orderMessage/markRead/${params}`, null, success);
    },
    // 标记订单消息为全部已读
    markAllRead: (params, success) => {
        return request('POST', `${API}/v1/shop/orderMessage/markAllRead`, params, success);
    },
    //获取收款单核销记录
    findSaleOrderIncomeList: (params, success) => {
        return request('GET', `${API}/v1/finance/income/findSaleOrderIncomeList/${params}`, params, success)
    },
    //修改销售单商品备注
    modifyItemRemark: (params, success) => {
        return request('POST', `${API}/v1/shop/order/modifyItemRemark`, params, success)
    },
    //客户订货统计（最近两年）
    clientSaleReport: (params, success) => {
        return request('GET', `${API}/v1/shop/order/clientSaleReport`, params, success)
    },
    // 规格销售报表-按规格统计列表
    productSaleReportSearchList: (params, success) => {
        return request('GET', `${API}/v1/shop/order/productSaleReportSearchList`, params, success)
    },
    // 分类销售报表-按分类统计列表
    goodsTypeSaleReportSearchList: (params, success) => {
        return request('GET', `${API}/v1/shop/order/goodsTypeSaleReportSearchList`, params, success)
    },
    // 添加日志
    saveOrderLog: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/saveOrderLog`, params, success)
    },
    //
    getOrderMoneyRestrictDto: (params, success) => {
        return request('GET', `${API}/v1/sale/saleOrder/getOrderMoneyRestrictDto/${params}`, params, success)
    },
    //订货端提交发票信息
    commitInvoiceInfo: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/commitInvoiceInfo`, params, success)
    },

    //保存转账申请
    saveTransferOffline: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/noAuth/saveTransferOffline`, params, success)
    },


};
export const PurchaseFetch = {
    searchSmartPurchaseList: (params, success) => {
        return request('GET', `${API}/v1/shop/purchase/searchSmartPurchaseList`, params, success)
    },
};
//系统模块
export const SystemFetch = {
    //获取验证码
    getPhoneCode: (params, success) => {
        return request('GET', `${API}/v1/account/bindPhoneCode`, params, success)
    },
    //绑定手机号
    bindByCodePhone: (params, success) => {
        return request('POST', `${API}/v1/account/bindPhone2`, params, success)
    },
    //订货端获取企业设置
    getShopCompanySetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getShopCompanySetting/${params}`, params, success)
    },
    //管理端-第一步点击就记录体验信息-体验
    experienceRegister: (params, success) => {
        return request('POST', `${API}/v1/account/experienceRegister`, params, success)
    },
    //管理端-体验
    experience: (params, success) => {
        return request('POST', `${API}/v1/account/getExperienceUser`, { ...params, type_: 1 }, success)
    },
    //订货端-体验
    shopExperience: (params, success) => {
        return request('POST', `${API}/v1/account/getExperienceUser`, { ...params, type_: 2 }, success)
    },
    //登陆
    login: (params, success) => {
        return request('POST', `${API}/v1/account/login`, params, success)
    },
    //混合登陆（兼容账号跟手机号去登陆）
    loginMixed: (params, success) => {
        return request('POST', `${API}/v1/account/mixedLogin`, params, success)
    },
    //登陆
    logout: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/logout`, params, success)
    },
    //验证验证码
    checkRegistCode: (params, success) => {
        return request('POST', `${API}/v1/account/checkRegistCode`, params, success)
    },
    //创建企业
    createCompany: (params, success) => {
        return request('POST', `${API}/v1/account/createCompany`, params, success)
    },
    //获取重置密码验证码
    resetCode: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/resetCode`, params, success)
    },
    //发送注册验证码
    registCode: (params, success) => {
        return request('GET', `${API}/v1/account/registCode`, params, success)
    },
    //发送注册验证码--pc分享出来的注册
    registCodeNoAuth: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/registCode`, params, success)
    },
    //校验注册验证码--pc分享出来的注册
    checkNoAuthRegistCode: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/checkRegistCode`, params, success)
    },
    //快速注册-订货端注册优化优化
    checkQuickRegistCode: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/quickRegist`, params, success)
    },
    //绑定刚注册的账号--pc分享出来的注册，完成注册后继续绑定
    completeNoAuthRegist: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/completeRegist`, params, success)
    },
    //发送绑定验证码
    bindCode: (params, success) => {
        return request('GET', `${API}/v1/base/user/bindPhoneCode`, params, success)
    },
    //验证绑定手机的验证码
    checkBindCode: (params, success) => {
        return request('POST', `${API}/v1/base/user/checkBindPhoneCode`, params, success)
    },
    //绑定手机号
    bindPhone: (params, success) => {
        return request('POST', `${API}/v1/base/user/bindPhone`, params, success)
    },
    //订货端重置密码
    resetPasswordByShop: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/resetPasswordByShop`, params, success)
    },
    //获取重置密码验证码---管理端
    resetCodeByManager: (params, success) => {
        return request('GET', `${API}/v1/account/resetCode`, params, success)
    },
    //管理端重置密码---管理端
    resetPasswordByManager: (params, success) => {
        return request('POST', `${API}/v1/account/resetPassword`, params, success)
    },
    //获取登陆用户信息
    getClientInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getClientInfo`, params, success)
    },
    //获取登陆用户信息
    getClientById: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getClientById/${params}`, params, success)
    },
    //获取地址
    listAll: (params, success) => {
        return request('GET', `${API}/v1/shop/address/listByLoginUser`, params, success)
    },
    //获取开票信息
    getInvoiceList: (params, success) => {
        return request('GET', `${API}/v1/client/clientInvoice/getListByClientId`, params, success)
    },
    //保存开票信息
    saveInvoice: (params, success) => {
        return request('BODY', `${API}/v1/client/clientInvoice/save`, params, success)
    },
    //删除开票信息
    deleteInvoice: (params, success) => {
        return request('GET', `${API}/v1/client/clientInvoice/deleteById/${params}`, params, success)
    },
    //获取地址
    deleteAddress: (params, success) => {
        return request('GET', `${API}/v1/shop/address/deleteById/${params}`, params, success)
    },
    //保存、修改地址
    saveAddress: (params, success) => {
        return request('BODY', `${API}/v1/shop/address/save`, params, success)
    },
    //设置默认地址
    setDefalue: (params, success) => {
        return request('POST', `${API}/v1/shop/address/setDefalu`, params, success)
    },
    //设置默认地址
    getUserInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfo`, params, success)
    },
    // 保存用户客户信息
    saveUserClientInfo: (params, success) => {
        return request('POST', `${API}/v1/shop/account/saveUserClientInfo`, params, success)
    },
    // 保存用户头像
    updateUserHeadImg: (params, success) => {
        return request('POST', `${API}/v1/shop/account/updateUserHeadImg`, params, success)
    },
    //获取商品设置信息
    getGoodsSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/goodsSetting/getByCompanyId/${params}`, params, success)
    },
    //获取销售设置信息
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting`, params, success)
    },
    // 获取文件上传的token信息
    getUploadToken: (params, success) => {
        return request('GET', `${API}/v1/common/upload/getUploadToken`, params, success)
    },
    // 获取公司基本信息
    getCompanyInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getCompanyById/` + params, params, success)
    },
    //取当前登录人关联的所有账号信息列表
    getAccountList: (params, success) => {
        return request('GET', `${API}/v1/base/user/getAccountList`, params, success)
    },
    //获取客户关联的员工列表
    getStaffList: (params, success) => {
        return request('GET', `${API}/v1/base/user/searchList`, params, success)
    },
    // 获取商城主题
    getThemeSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getThemeSetting/${params}`, params, success)
    },
    //获取当前登录用户的authCode
    getAuthCode: (params, success) => {
        return request('GET', `${API}/v1/account/getAuthCode`, params, success)
    },
    //获取当前登录用户的authCode
    getMobileShopVersion: (params, success) => {
        return request('GET', `${API}/v1/account/getMobileShopVersion`, params, success)
    },

    //获取省市县
    buildSimpleRegionTree: (params, success) => {
        return request('GET', `${API}/v1/common/helper/buildSimpleRegionTree`, params, success)
    },
    // 获取街道
    listStreetById: (params, success) => {
        return request('GET', `${API}/v1/common/helper/listStreetById/${params}`, params, success)
    },
    //获取微信jsticket
    getWxTicket: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/getWxTicket`, params, success)
    },
    //获取微信jsticket/
    getWxQrcode: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/getWxQrCode`, params, success)
    },
    //获取分享设置
    getShareSetting: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShareSetting`, params, success)
    },
    //保存分享设置
    saveShareSetting: (params, success) => {
        return request('POST', `${API}/v1/shop/account/saveShareSetting`, params, success)
    },
    // 添加客服消息
    addMsgForClient: (params, success) => {
        return request('POST', `${API}/v1/client/client/addMsgForClient`, params, success)
    },
    // 获取指定对话消息列表
    searchMsgList: (params, success) => {
        return request('GET', `${API}/v1/client/client/searchMsgList`, params, success)
    },
    // 获取指定对话消息列表
    markReadByClient: (params, success) => {
        return request('POST', `${API}/v1/client/client/markReadByClient/${params}`, params, success)
    },
    // 获取消息对话列表
    searchGroupMsgListForClient: (params, success) => {
        return request('GET', `${API}/v1/client/client/searchGroupMsgListForClient`, params, success)
    },
    // 搜索框  获取焦点 未输入搜索关键词前 =》 查出客户搜索历史关键词5个
    searchClientKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchClientKeyWordList`, params, success)
    },
    // 关键词搜索
    searchKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchKeyWordList`, params, success)
    },
    // 删除客户指定的搜索关键词
    toDeleteKeywordsById: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsSearchLog/deleteById/${params}`, params, success)
    },

    // 删除客户所有的搜索关键词
    toDeleteClientAllKeywords: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsSearchLog/toDeleteClientAllKeywords`, params, success)
    },

    // 个人中心
    getShopUserInfoForHome: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfoForHome`, params, success)
    },
    // 获取商城帮助
    shopHelpTypeSearchList: (params, success) => {
        return request('GET', `${API}/v1/shopHelp/shopHelpType/noAuth/searchListForShop`, params, success)
    },
    // 获取微信用户信息
    getWeChatUserInfo: (params, success) => {
        return request('GET', `${API}/v1/setting/weChatSetting/noAuth/getWeChatUserInfo`, params, success)
    },
    // 微信openId登录
    openIdLogin: (params, success) => {
        return request('POST', `${API}/v1/account/openIdLogin`, params, success)
    },
    // 绑定微信openId
    bindOpenId: (params, success) => {
        return request('POST', `${API}/v1/account/bindOpenId`, params, success)
    },
    // 登录并绑定微信openId
    bindOpenIdForLogin: (params, success) => {
        return request('POST', `${API}/v1/account/bindOpenIdForLogin`, params, success)
    },
    // 保存/修改商城注册记录
    shopRegisterSave: (params, success) => {
        return request('BODY', `${API}/v1/shopRegister/noAuth/save`, params, success)
    },
    //
    getApplicationStatus: (params, success) => {
        return request('GET', `${API}/v1/application/noAuth/getApplicationStatus`, params, success)
    },
    //
    getSubsidiaryById: (params, success) => {
        return request('GET', `${API}/v1/shop/subsidiary/getById/${params}`, params, success)
    },
    //保存供应商加盟
    saveProviderApply: (params, success) => {
        return request('BODY', `${API}/v1/system/security/saveProviderApply`, params, success)
    },
    //发送供应商加盟验证码
    providerApplyCode: (params, success) => {
        return request('GET', `${API}/v1/system/security/noAuth/providerApplyCode`, params, success)
    },
    visitLog: (params, success) => {
        return request('BODY', `${API}/v1/system/security/visitLog`, params, success)
    },
    companyShopToPcMgr: (params, success) => {
        return request('POST', `${API}/v1/client/companyShop/toMgr/${params}`, params, success)
    },
    // 取当前登录人关联的所有账号信息列表
    getByHost(params) {
        return request(`GET`, `${API}/v1/base/company/noAuth/getByHost`, params);
    },
    // 获取指定企业微信设置
    getByCompanyId(params) {
        return request(`GET`, `${API}/v1/setting/weChatSetting/noAuth/getByCompanyId`, params);
    },
    // 获取混提活动价格规则
    getMixProductPriceItem(params, success) {
        return request(`GET`, `${API}/v1/shop/mixProductPromotion/getMixProductPriceItem`, params, success);
    },
    // 获取混提达量列表
    getGoodsMixProductPromotionActivityList(params, success) {
        return request(`GET`, `${API}/v1/shop/mixProductPromotion/getGoodsMixProductPromotionActivityList/${params}`, params, success);
    },
    // 通过混提达量活动ID获取相关信息
    getMixProductPromotionActivityListById(params, success) {
        return request(`GET`, `${API}/v1/shop/mixProductPromotion/getMixProductPromotionActivityListById/${params}`, params, success);
    },
};

// 统一支付入口（主要针对微信/支付宝，不同场景，不同渠道的统一）
export const UniPayFetch = {

    // 创建支付单，向渠道下单，获取收银台信息
    pay: (payScene, transactionMode, uniPayDto, success) => {
        return request('BODY', `${API}/v1/uni-pay/pay/${payScene}/${transactionMode}`, uniPayDto, success)
    },

    // 创建充值单，向渠道下单，获取收银台信息
    recharge: (payScene, transactionMode, uniPayDto, success) => {
        return request('BODY', `${API}/v1/uni-pay/recharge/${payScene}/${transactionMode}`, uniPayDto, success)
    },
}

//支付模块接口
export const PayFetch = {
    //构建支付宝支付参数
    buildWebAlipayApi: (params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildWebAlipayApi`, params, success)
    },
    //构建订单合并支付支付宝支付请求
    buildCombinedPayWebAlipayApi: (params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildCombinedPayWebAlipayApi`, params, success)
    },
    //获取微信支付参数
    buildWeixinScanpayApi: (orderId, success) => {
        return request('POST', `${API}/v1/shop/pay/buildWeixinScanpayApi/${orderId}`, orderId, success)
    },
    //获取微信支付参数
    rechargeByWechat: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/rechargeByWechat`, params, success)
    },
    //微信公众号支付
    pubWeixinApi: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/pubWeixinApi`, params, success)
    },
    weixinH5Api: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinH5Api`, params, success)
    },
    //微信外h5订单合并支付
    weixinCombinedPayH5Api: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinCombinedPayH5Api`, params, success)
    },
    // 合并付款 公众号支付
    weixinCombinedPayApi: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinCombinedPayApi`, params, success)
    },
    //合并付款预存款和线下付
    combinedPayByPre: (params, success) => {
        return request('POST', `${API}/v1/finance/combinedPay/combinedPayByPre`, params, success)
    },
    //构建预存款充值支付宝支付请求
    buildPreBalanceRechargeAlipayApi: (params, success) => {
        return request('body', `${API}/v1/shop/pay/buildPreBalanceRechargeAlipayApi`, params, success)
    },
    //预存款充值微信外h5支付
    weixinPreBalanceRechargeH5Api: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinPreBalanceRechargeH5Api`, params, success)
    },
    //预存款充值微信公众号支付
    pubPreBalanceRechargeWeixinApi: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/pubPreBalanceRechargeWeixinApi`, params, success)
    },
    //获取订单财务状态
    getFinanceStatus: (params, success) => {
        return request('GET', `${API}/v1/finance/combinedPay/getFormStatus/${params}`, params, success)
    },
    //京东支付
    orderJdPay: (params, success) => {
        return request('GET', `${API}/v1/jdPay/apply/orderJdPay`, params, success)
    },
};
//代客下单
export const ClientFetch = {
    //创建订单
    saveByClient: (params, success) => {
        return request('POST', `${API}/v1/sale/valetSale/saveByClient`, params, success)
    },
    //根据规格ids串获取商品优惠信息
    getPromotionByProductIdsByClient: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/getPromotionByProductIdsByClient`, params, success)
    },
    //获取订单优惠信息
    getPromotionByClient: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/getPromotionByClient`, params, success)
    },
    //商城商品分页查询
    searchListForShopByClient: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/noAuth/searchListForShopByClient`, params, success)
    },
    //客户列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/client/client/searchList`, params, success)
    },
    //新增客户
    addNewClient: (params, success) => {
        return request('POST', `${API}/v1/client/client/save`, params, success)
    },
    // 获取企业线下收款账号
    listBankCard: (params, success) => {
        return request('GET', `${API}/v1/finance/bankCard/listByClientId/${params}`, params, success)
    },
    // 获取企业线下收款账号 
    listBankCompanyCard: (params, success) => {
        return request('GET', `${API}/v1/finance/bankCard/listBankCompanyCardByCapitalAccountId/${params}`, params, success)
    },
    // 获取企业线下收款账号 
    listBankCardByCapitalAccountId: (params, success) => {
        return request('GET', `${API}/v1/capital/capitalAccount/listBankCardByCapitalAccountId/${params}`, params, success)
    },
    // 根据客户id获取客户资金帐户的帐户实名信息 
    getAutonymlByClientId: (params, success) => {
        return request('GET', `${API}/v1/capital/capitalAccount/getAutonymlByClientId`, params, success)
    },
    // 实名认证新接口
    submitAuthInfo: (params, success) => {
        return request('BODY', `${API}/v1/pisen/customer/submitAuthInfo`, params, success)
    },
    // 验证客户金额 短信 
    verifyAuthInfo: (params, success) => {
        return request('BODY', `${API}/v1/pisen/customer/verifyAuthInfo`, params, success)
    },
    // 获取信息
    querySlClientVo: (params, success) => {
        return request('GET', `${API}/v1/pisen/customer/querySlClientVo`, params, success)
    },
    // 查询小额往账鉴权的转账状态
    smallAmountTransferQuery: (params, success) => {
        return request('GET', `${API}/v1/pisen/customer/smallAmountTransferQuery`, params, success)
    },
    // 再次获取短信验证码
    repSubmitAuthInfo: (params, success) => {
        return request('BODY', `${API}/v1/pisen/customer/repSubmitAuthInfo`, params, success)
    },
};
//应用
export const ApplicationFetch = {
    getMiniProgram: (params, success) => {
        return request('GET', `${API}/v1/application/miniProgram/getByCompanyId/${params}`, params, success)
    }
};
//积分商城模块
export const IntegralFetch = {
    //分页获取积分商品表列表
    searchList: (params, success) => {
        return request('GET', `${API}/api/v1/score/goods/searchList`, params, success)
    },
    //分页获取积分商品表列表
    getById: (params, success) => {
        return request('GET', `${API}/api/v1/score/goods/getById/${params}`, params, success)
    },
    //获取积分商品分类列表
    listAll: (params, success) => {
        return request('GET', `${API}/api/v1/score/goods/listAllType`, params, success)
    },
    //获取我的积分信息
    getMyScore: (params, success) => {
        return request('GET', `${API}//api/v1/buyer/mine/getMyScore`, params, success)
    },
    //获取我的积分明细列表
    getMyScoreLog: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getMyScoreLog`, params, success)
    },
    //获取积分规则设置
    getRulesSetting: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getRulesSetting`, params, success)
    },
    //创建积分订单
    createOrder: (params, success) => {
        return request('POST', `${API}/api/v1/score/order/createOrder`, params, success)
    },
    //分页获取积分订单信息列表
    searchOrderList: (params, success) => {
        return request('GET', `${API}/api/v1/score/order/searchList`, params, success)
    },
    //获取积分订单信息
    getOrderById: (params, success) => {
        return request('GET', `${API}/api/v1/score/order/getById/${params}`, params, success)
    },
    //根据快递单号获取物流信息
    getLogisticInfo: (params, success) => {
        return request('GET', `${API}/api/v1/common/data/getLogisticInfo`, params, success)
    },
    //根据快递单号获取物流信息
    getMySign: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getMySign`, params, success)
    },
    //签到
    sign: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/sign`, params, success)
    },
    //签到
    getScoreCompanySetting: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getScoreCompanySetting`, params, success)
    },
};
// 退货
export const SaleBackFetch = {
    // 申请退货
    save: (params, success) => {
        return request('BODY', `${API}/v1/sale/saleBack/save`, params, success)
    },
    // 退货列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/searchList`, params, success)
    },
    // 退货列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/getById/${params}`, params, success)
    },
};
// 销售中心
export const DistributionFetch = {
    // 企业分销设置
    getSetting: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionRebateSetting/getSetting`, params, success)
    },
    // 客户分销信息
    getCountDateByClientId: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/getDistributionInfo`, params, success)
    },
    // 客户业绩统计
    clientStatistics: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/clientStatistics/${params}`, params, success)
    },
    // 分页查询当前客户下级列表
    searchChildrenClientList: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/searchChildrenClientList`, params, success)
    },
    // 订单统计
    searchRebateList: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/searchRebateList`, params, success)
    },
    //推广商品列表
    searchDistributionGoods: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/noAuth/searchDistributionGoods`, params, success)
    },
    //获取当前登录用户的分销店铺名称
    getClientShopName: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/getClientShopName`, params, success)
    },
    //修改当前登录用户的分销店铺名称
    modifyClientShopName: (params, success) => {
        return request('BODY', `${API}/v1/distribution/shopDistribution/modifyClientShopName`, params, success)
    },
    //账户明细
    // searchLogList: (params, success) => {
    //     return request('GET', `${API}/v1/distribution/distributionPayLog/searchList`, params, success)
    // },
    //账户明细
    searchLogList: (params, success) => {
        return request('GET', `${API}/v1/fn/capitalRecord/searchList`, params, success)
    },
    //提现记录
    searchLWithdrawalLogList: (params, success) => {
        return request('GET', `${API}/v1/capital/cashOut/searchList`, params, success)
    },
    //删除资金账户信息
    deleteWithdrawalLogList: (params, success) => {
        return request('BODY', `${API}/v1/capital/cashOut/delete`, params, success)
    },
    cancelWithdrawalLog: (params, success) => {
        return request('BODY', `${API}/v1/capital/cashOut/cancel`, params, success)
    },
    //获取资金账户信息
    getCapitalAccountById: (params, success) => {
        return request('GET', `${API}/v1/capital/capitalAccount/getById/${params}`, params, success)
    },
    //获取客户的分销提现设置
    getDistributionCashOutSetting: (params, success) => {
        return request('GET', `${API}/v1/distribution/shopDistribution/getDistributionCashOutSetting`, params, success)
    },
    //获取客户的分销提现设置
    save: (params, success) => {
        return request('BODY', `${API}/v1/capital/cashOut/save`, params, success)
    },
    //获取提现验证码
    confirmCashOutCode: (params, success) => {
        return request('POST', `${API}/v1/capital/cashOut/confirmCashOutCode/${params}`, params, success)
    },
    //获取提现验证码
    confirmCashOut: (params, success) => {
        return request('BODY', `${API}/v1/capital/cashOut/confirmCashOut`, params, success)
    },
    //获取商品预计奖励
    getGoodsDistributionDataById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/getGoodsDistributionDataById/${params}`, params, success)
    },
    //申请提现或支付短信动态码
    applicationTextMsgDynamicCode: (params, success) => {
        return request('BODY', `${API}/v1/capital/applicationTextMsgDynamicCode`, params, success)
    },
    //支付确认短信验证码
    memberTranVerifyTextMsgs: (params, success) => {
        return request('BODY', `${API}/v1/capital/memberTranVerifyTextMsgs`, params, success)
    },

    //测试挂账
    registerBillSupportWithdraw: (params, success) => {
        return request('BODY', `${API}/v1/capital/registerBillSupportWithdraw`, params, success)
    },


};
// 预存款
export const DepositFetch = {
    //查询企业的预存款充值设置明细
    searchItemListByLogin: (params, success) => {
        return request('GET', `${API}/v1/shop/preBalanceRecharge/searchItemListByLogin`, params, success)
    },
    //申请预存款充值充值
    applyRecharge: (params, success) => {
        return request('BODY', `${API}/v1/shop/preBalanceRecharge/applyRecharge`, params, success)
    },
    // 查询存款充值记录
    getById: (params, success) => {
        return request('POST', `${API}/v1/shop/preBalanceRecharge/getById/${params}`, params, success)
    },
};
// 代金券接口
export const VouchersFetch = {
    // 获取代金券列表
    searchClientVouchersList: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/searchClientVouchersList`, params, success)
    },
    // 更新代金券列表
    updateIsReadByIds: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/updateIsReadByIds/${params}`, params, success)
    },
    // 获取代金券明細
    searchVouchersLog: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchersLog/searchList`, params, success)
    },
    // 根据商品获取代金券
    searchListByGoodsIdAndClientId: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/searchListByGoodsIdAndClientId`, params, success)
    },
    // 根据规格获取代金券
    searchListByProductIdAndClientId: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/searchListByProductIdAndClientId`, params, success)
    },
};
// 车型相关
export const SearchFetch = {
    // 汽车品牌搜索
    getAcBrandList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesBrand/noAuth/getAcBrandList`, params, success);
    },
    // 汽车车系搜索
    getAcVersionListByQuery: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesVersion/noAuth/getAcVersionListByQuery`, params, success);
    },
    // 汽车车型搜索
    getAcTypeList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesType/noAuth/getAcTypeList`, params, success);
    },
    // 汽车车版本搜索
    getAcCarList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesCar/noAuth/getAcCarList`, params, success);
    },
    // VIN码搜索车版本
    getByVin: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesCar/noAuth/getByVin/${params}`, params, success);
    },
    //根据OE码+车品牌 查询OE码关联的对应品牌下的车版本列表
    searchCarListByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/accessories/goodsOeType/searchList`, params, success);
    },
    //根据商品ID+OE码 查询关联车型品牌列表
    getAcBrandListByOe: (params, success) => {
        return request('BODY', `${API}/v1/accessories/goodsOeType/getAcBrandListByOe`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应品牌下的车系列表
    getAcVersionListByOeAndAcBrand: (params, success) => {
        return request('BODY', `${API}/v1/accessories/goodsOeType/getAcVersionListByOeAndAcBrand`, params, success);
    },
    //根据OE码+车品牌 查询OE码关联的对应品牌下的车版本列表
    getGoodsOeCarListByOeAndAcBrand: (params, success) => {
        return request('BODY', `${API}/v1/accessories/goodsOeType/getGoodsOeCarListByOeAndAcBrand`, params, success);
    },

};
//员工相关
export const ClientAccountFetch = {
    //获取客户员工列表
    searchList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/user/clientAccount/searchList`, _params, _success, _error)
    },
    //新增/编辑客户员工
    saveClientAccount: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/user/clientAccount/saveClientAccount`, _params, _success, _error)
    },
    // 删除客户员工
    deleteClientAccount: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/user/clientAccount/deleteClientAccount/${_params}`, _params, _success, _error)
    },
    // 重置员工密码
    modifyClientAccountPassword: (_params, _success, _error) => {
        return request('POST', `${API}/v1/user/clientAccount/modifyClientAccountPassword`, _params, _success, _error)
    },
    // 启用客户子账号
    validClientAccount: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/user/clientAccount/validClientAccount`, _params, _success, _error)
    },
    // 禁用客户子账号
    invalidClientAccount: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/user/clientAccount/invalidClientAccount`, _params, _success, _error)
    },

};
//询价
export const EnquiryFetch = {
    //根据Vin码搜索车版本
    getByVin: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/accessories/accessoriesCar/noAuth/getByVin`, _params, _success, _error)
    },
    //分页获取产品组列表
    searchListForShop: (_params, _success, _error) => {
        return request('GET', `${API}/v1/accessories/productGroup/noAuth/searchListForShop`, _params, _success, _error)
    },
    //分页获取询价记录
    getClientSearchVinLong: (_params, _success, _error) => {
        return request('GET', `${API}/v1/client/client/getClientSearchVinLong`, _params, _success, _error)
    },
    //保存/修改询价单
    save: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/save`, _params, _success, _error)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getById: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/inquirySheet/getById/${_params}`, _params, _success, _error)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getGoodsByIdAndItemId: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getGoodsByIdAndItemId`, _params, _success, _error)
    },
    // 根据标准名称/OE码 查询订货端商品规格信息表
    getGoodsByProductGroupIdOrOeNumber: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getGoodsByProductGroupIdOrOeNumber`, _params, _success, _error)
    },
    // 根据OE查询EPC图片
    getEpcImgByOeNumber: (_params, _success, _error) => {
        return request('GET', `${API}/v1/accessories/productGroup/noAuth/getEpcImgByOeNumber/${_params}`, _params, _success, _error)
    },
    // 分页获取询价单列表
    inquirySheetSearchList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/inquirySheet/searchList`, _params, _success, _error)
    },
    // 分页获取采购单列表
    buyingSheetSearchList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/buyingRequisition/searchList`, _params, _success, _error)
    },
    // 根据VIN+想查的状态码 获取询价单列表
    getListByVin: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getListByVin`, _params, _success, _error)
    },
    // 删除vin查询记录
    deleteSearchVinLongById: (_params, _success, _error) => {
        return request('GET', `${API}/v1/client/client/deleteSearchVinLongById/${_params}`, _params, _success, _error)
    },
    // 根据VIN码 查询一级分类列表
    getFirstTypeListByVin: (_params, _success, _error) => {
        return request('POST', `${API}/v1/epc/getFirstTypeListByVin`, _params, _success, _error)
    },
    // 根据一级分类 查询二级分类列表
    getSecondTypeListByFirstType: (_params, _success, _error) => {
        return request('POST', `${API}/v1/epc/getSecondTypeListByFirstType`, _params, _success, _error)
    },
    // 选中了二级分类 查询三级分类列表
    getThirdTypeListBySecondType: (_params, _success, _error) => {
        return request('POST', `${API}/v1/epc/getThirdTypeListBySecondType`, _params, _success, _error)
    },
    // 获取替换OE
    getReplaceCountByOe: (_params, _success, _error) => {
        return request('GET', `${API}/v1/accessories/goodsOe/getByOe/${_params}`, _params, _success, _error)
    },

    // 保存配件备注
    saveImgAndRemark: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/buyingRequisition/saveImgAndRemark`, _params, _success, _error)
    },
    // 保存配件备注
    getByInquiryItemId: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/buyingRequisition/getByInquiryItemId/${_params}`, _params, _success, _error)
    },

};
//平安支付
export const PABankFetch = {
    bindBankCard: (_params, _success, _error) => {
        return request('GET', `${API}/v1/pabank/onlinePay/bindBankCard`, _params, _success, _error)
    },
    bindBankCard2: (_params, _success, _error) => {
        return request('GET', `${API}/v1/pabank/frontPay/bindCard`, _params, _success, _error)
    },
    sendPayOrderSMS: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/pabank/onlinePay/sendPayOrderSMS2`, _params, _success, _error)
    },
    payOrder: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/pabank/onlinePay/payOrder2`, _params, _success, _error)
    },
    sendPayRechargeSMS: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/pabank/onlinePay/sendPayRechargeSMS2`, _params, _success, _error)
    },
    payRecharge: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/pabank/onlinePay/payRecharge2`, _params, _success, _error)
    },
    //分页获取 平安提供 银行列表
    searchBankList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/capital/searchBankList`, _params, _success, _error)
    },
    //分页获取 平安提供 省份列表
    searchProvinceList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/capital/searchProvinceList`, _params, _success, _error)
    },
    //分页获取 平安提供 城市列表
    searchCityList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/capital/searchCityList`, _params, _success, _error)
    },
    //分页获取 平安提供 支行列表
    searchBranchBankList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/capital/searchBranchBankList`, _params, _success, _error)
    },
    //会员绑定提现账户-银联鉴权
    bindRelateAcct: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/bindRelateAcct`, _params, _success, _error)
    },
    //会员绑定提现账户-银联鉴权
    checkAmount: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/checkAmount`, _params, _success, _error)
    },
    //会员绑定提现账户-银联鉴权
    bindRelateAccReUnionPay: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/bindRelateAccReUnionPay`, _params, _success, _error)
    },
    //会员解绑提现账户
    unbindRelateAcct: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/unbindRelateAcct`, _params, _success, _error)
    },
    //解绑银联绑卡关系
    deleteBankCard: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/pabank/onlinePay/deleteBankCard`, _params, _success, _error)
    },
    //删除银行卡
    deleteCapitalAccountBankCard: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/capitalAccount/deleteCapitalAccountBankCard`, _params, _success, _error)
    },
    bindRelateAcctV3: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/bindRelateAcctV3`, _params, _success, _error)
    },
    checkMsgCodeWithCorp: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/checkMsgCodeWithCorp`, _params, _success, _error)
    },
    checkAmountWithCorp: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/capital/checkAmountWithCorp`, _params, _success, _error)
    },
    // 支持提现银行卡
    querySupportCashBank: (_params, _success,) => {
        return request('GET', `${API}/v1/capital/querySupportCashBank`, _params, _success,)
    },
};
//换货单
export const ExchangeGoods = {
    //商城商品分页查询
    searchProductList: (params, success) => {
        return request('GET', `${API}/v1/shop/exchangeGoods/searchProductList`, params, success)
    },
    apply: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/exchangeGoods/apply`, _params, _success, _error)
    },
    searchList: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/exchangeGoods/searchList`, _params, _success, _error)
    },
    cancel: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/exchangeGoods/cancel`, _params, _success, _error)
    },
    getById: (_params, _success, _error) => {
        return request('GET', `${API}/v1/shop/exchangeGoods/getById/${_params}`, _params, _success, _error)
    },
    back: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/exchangeGoods/back`, _params, _success, _error)
    },
    receive: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/exchangeGoods/receive`, _params, _success, _error)
    },
};

// 信用购
export const CreditBuyFetch = {
    getCreditQuota: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopCorporationClientCreditQuota/getCreditQuota`, params, success)
    },
    searchUseLogList: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopCorporationClientCreditQuota/searchUseLogList`, params, success)
    },
    groupQueryClientBusinessCredit: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopClientBusinessCredit/groupQueryClientBusinessCredit`, params, success)
    },
    searchCreditBuyOrderList: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopCorporationClientCreditQuota/searchOrderList`, params, success)
    },
};

// 信用支付
export const CreditPayFetch = {
    orderCreditPay: (params, success) => {
        return request('BODY', `${API}/v1/businessCredit/shopClientBusinessCredit/orderCreditPay`, params, success)
    },
};

// 延迟付
export const DelayPayFetch = {
    searchQuotaList: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopProviderClientDelayQuota/searchQuotaList`, params, success)
    },
    searchUseLogList: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopProviderClientDelayQuota/searchUseLogList`, params, success)
    },
    searchDelayPayOrderList: (params, success) => {
        return request('GET', `${API}/v1/businessCredit/shopProviderClientDelayQuota/searchOrderList`, params, success)
    },
};

// 金品豆
export const GoldBeanFetch = {
    // 获取用户金品豆信息
    getGoldBeanByClientId: (params, success) => {
        return request('GET', `${API}/v1/shop/goldBeanRebate/getByClientId/${params}`, params, success)
    },
    // 查询客户使用金品豆明细
    searchGoldBeanRecordListByClient: (params, success) => {
        return request('GET', `${API}/v1/shop/goldBeanRebate/searchGoldBeanRecordListByClient`, params, success)
    },
};

// 交易回单
export const TradeReceiptFetch = {
    // 交易回单列表，通过客户id查询
    listByCapitalAccountId: (capitalAccountId, page, pageSize, query, success) => {
        return request('BODY', `${API}/v1/tradeReceipt/listByCapitalAccountId/${capitalAccountId}/${page}/${pageSize}`,
            query, success)
    },
};


