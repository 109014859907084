const hostname = window.location.hostname;
const origin = (typeof window.location.origin === 'undefined' ?
    (window.location.protocol + "//" + window.location.host) : window.location.origin);
const prodHost = 'pisenb2b';
// const prodHost = '3ceasyb2b';
const testHost = 'yunma99';
const devHost = `http://${hostname}`;
const isCustomHost = (hostname.indexOf(prodHost) === -1 && hostname.indexOf(testHost) === -1 && hostname.indexOf('192.168') === -1 && hostname.indexOf('localhost') === -1);
const isDefaultUrl = true;

//打包TEST时 如果为TRUE则是UAT地址 可以优化成打包时传入变量 @uat
// const isUat = false;



const UrlUtil = {
    getAPI() {
        if (process.env.API_ENV === 'production') {
            return `https://api.${prodHost}.com`;
        } else if (process.env.API_ENV === 'test') {
            return `//api.${testHost}.com`;
        } else if (process.env.API_ENV === 'uat') {
            return `https://api-uat.${prodHost}.com`;
        } else {
            // return `http://api.${testHost}.com`;
            return `${devHost}:7002`;
            // return `http://192.168.0.99:7002`;
            // return `http://api.yunma99.com`;
            // return `https://api.qipeidd.com`;
        }
    },
    getAccountUrl: (companyID) => {
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return !companyID ? `https://store${companyID || 1}.${prodHost}.com` : `https://account.${prodHost}.com`
        } else if (process.env.API_ENV === 'test') {
            return companyID ? `http://store${companyID || 1}.${testHost}.com` : `http://account.${testHost}.com`
        } else if (process.env.API_ENV === 'uat') {
            return `https://uat1.${prodHost}.com`;
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:7001`;
        }
    },

    getMShopUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://m.${prodHost}.com`
        } else if (process.env.API_ENV === 'test') {
            return `http://m.${testHost}.com`
        } else if (process.env.API_ENV === 'uat') {
            return `https://m-uat.${prodHost}.com`
        } else {
            return `${devHost}:7005`;
        }
    },
    getMMgrUrl() {
        if (process.env.API_ENV === 'production') {
            // return `http://mtest.qipeidd.com`;
            return `https://mgr.${prodHost}.com`;
        } else if (process.env.API_ENV === 'test') {
            return `http://mgr.${testHost}.com`;
        } else if (process.env.API_ENV === 'uat') {
            return `https://mgr-uat.${prodHost}.com`;
        } else if (process.env.API_ENV === 'development') {
            return `${devHost}:7004`;
        }
    },
    getPcShopUrl(id) {
        if (process.env.API_ENV === 'production') {
            return isDefaultUrl ? `https://store${id}.${prodHost}.com` : origin;
        } else if (process.env.API_ENV === 'test') {
            return isDefaultUrl ? `http://store${id}.${testHost}.com` : origin;
        } else if (process.env.API_ENV === 'uat') {
            return `https://uat1.${prodHost}.com`;
        } else if (process.env.API_ENV === 'development') {
            return `${devHost}:7003`;
        }
    },
    getAccountUrl1: () => {
        if (process.env.API_ENV === 'production') {
            return `https://account.${prodHost}.com`
        } else if (process.env.API_ENV === 'test') {
            return `http://account.${testHost}.com`
        }  else if (process.env.API_ENV === 'uat') {
            return `https://account-uat.${prodHost}.com`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:7001`;
        }
    }
};
export default UrlUtil;
