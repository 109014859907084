import React from 'react';
import {
	Form,
	Input,
	Cascader, Modal,
} from 'antd';
import {SystemFetch} from "../../services/service";

const {TextArea} = Input;


class SettledForm extends React.Component {
	state = {
		addressTree: [],
	};
	formRef = React.createRef();
	formItemLayout = {
		labelCol: {span: 4},
		wrapperCol: {span: 18},
	};

	componentDidMount() {
		this.buildSimpleRegionTree();
	}

	buildSimpleRegionTree = () => {
		SystemFetch.buildSimpleRegionTree().then(rs => {
			if (rs.status === 1) {
				const children = rs.data.children;
				const formatData = (data) => {
					data.forEach(item => {
						if (item.children) {
							if (item.children.length > 0) {
								formatData(item.children);
							} else {
								delete item.children;
								item.isLeaf = false;
							}
						}
					})
				};
				formatData(children);
				this.setState({
					addressTree: children,
				}, () => {
				})
			}
		})
	};
	listStreetById = (id) => {
		const {addressTree} = this.state;
		SystemFetch.listStreetById(id).then(rs => {
			if (rs.status === 1) {
				addressTree.forEach(item => {
					if (item.children) {
						item.children.forEach(subItem => {
							if (subItem.children) {
								subItem.children.forEach(i => {
									if (i.id === id) {
										i.children = rs.data
									}
								})
							} else {
								if (subItem.id === id) {
									subItem.children = rs.data
								}
							}
						})
					} else {
						if (item.id === id) {
							item.children = rs.data
						}
					}
				});
				this.setState({
					addressTree: addressTree
				}, () => {
					const {current: {setFieldsValue}} = this.formRef;
					setFieldsValue(this.props.initialValues)
				});
			}
		})
	};


	// 获取街道
	loadData = (selectedOptions) => {
		const targetOption = selectedOptions[selectedOptions.length - 1];
		targetOption.loading = true;
		const {current: {setFieldsValue}} = this.formRef;
		const province_region_ = selectedOptions.map(item => item.name);
		const province_region_ids_ = selectedOptions.map(item => item.id);
		setFieldsValue({
			taker_region_: province_region_,
			taker_region_ids_: province_region_ids_
		});
		this.ref.handlePopupVisibleChange(false);
		this.setState({
			addressTree: [...this.state.addressTree],
		});
		/*SystemFetch.listStreetById(targetOption.id).then(rs => {
			if (rs.status === 1) {
				targetOption.loading = false;

				if (rs.data.length > 0) {
					targetOption.children = rs.data;
				} else {
					const {current: {setFieldsValue}} = this.formRef;
					const taker_region_ = selectedOptions.map(item => item.name);
					const taker_region_ids_ = selectedOptions.map(item => item.id);
					setFieldsValue({
						taker_region_: taker_region_,
						taker_region_ids_: taker_region_ids_
					});
					this.ref.handlePopupVisibleChange(false);
				}
				this.setState({
					addressTree: [...this.state.addressTree],
				});
			}
		})*/
	};
	addressChange = (value, selectedOptions) => {
		const province_region_ = selectedOptions.map(item => item.name);
		this.formRef.current.setFieldsValue({
			province_region_: province_region_,
		})
	};
	// 数据验证
	validate = () => {
		const {current: {validateFields}} = this.formRef;
		validateFields().then(values => {
			this.props.onOk(values);
		})

	};

	render() {
		const {addressTree} = this.state;
		const {visible, onCancel} = this.props;
		return (
			<Modal
				centered
				destroyOnClose
				visible={visible}
				title={'商家入驻'}
				width={544}
				className={'page-custom-modal'}
				onOk={this.validate}
				onCancel={onCancel}
			>
				<Form {...this.formItemLayout} ref={this.formRef}>

					<Form.Item label="您的手机" name="phone_" rules={[
						{required: true, whitespace: true, message: '请输入您的公司'},
						{
							pattern: /^1([3456789])\d{9}$/,
							message: '请输入正确的手机号码',
						}
					]}>
						<Input placeholder='请输入手机号码' maxLength={11}/>
					</Form.Item>

					<Form.Item label="您的公司" name="company_name_" rules={[
						{required: true, whitespace: true, message: '请输入您的公司'},
					]}>
						<Input placeholder='请输入您的公司'/>
					</Form.Item>

					<Form.Item label="省份" name={'province_region_ids_'} rules={[
						{type: 'array', required: true, message: '请选择省份'},
					]}>
						<Cascader
							ref={ele => this.ref = ele}
							options={addressTree}
							// loadData={this.loadData}
							onChange={this.addressChange}
							placeholder="请选择省份"
							fieldNames={{value: 'id', label: 'name'}}/>

					</Form.Item>
					<Form.Item label="省份" name={'province_region_'} style={{display: 'none'}}>
						<div/>
					</Form.Item>

					<Form.Item label="您的姓名" name="name_" rules={[
						{pattern: /^[A-Za-z0-9_\- \u4e00-\u9fa5]+$/, message: '请输入正确的您的姓名'},
						{required: true, whitespace: true, message: '请输入您的姓名'},
					]}>
						<Input placeholder='请输入您的姓名' maxLength={25}/>
					</Form.Item>

					<Form.Item label="备注" name={'remark_'}>
						<TextArea placeholder='请输入备注' maxLength={250}/>
					</Form.Item>

				</Form>
			</Modal>
		);
	}
}


export default SettledForm;
