import React, { Fragment } from 'react';
import classnames from 'classnames'
import { connect } from "dva";
import './style.scss'
import StorageUtil from "../../utils/storage-util";
import { LocalConst } from "../../utils/constant";
import { SystemFetch, ClientFetch } from "../../services/service";
import { message } from "antd";

@connect(state => ({ ...state.appModel }))
class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            userInfo: {}
        };
    }

    componentDidMount() {
        SystemFetch.getShopUserInfoForHome().then(rs => {
            if (rs.status) {
                this.setState({ userData: rs.data, })
            }
        });

        ClientFetch.querySlClientVo({}).then(res => {
            if (res.status === 1) {
                this.setState({ userInfo: { ...res.data.slClient, ...res.data.account }, customerType: res.data.customerType })
            }
        })

    }

    toPage = (item) => {
        const { create_step_ } = StorageUtil.getItem(LocalConst.ysClient);
        const { userData } = this.state;
        const { history } = this.props;
        if (item.router === '/credit/order/list' && !userData.enable_corporation_client_credit_quota_) { // 未开通信用购
            message.info('您暂未开通信用购');
            return;
        } else if (item.router === '/delay/order/list' && !userData.enable_provider_client_delay_quota_) { // 未开通延迟付
            message.info('您暂未开通延迟付');
            return;
        }

        if (item.router === "/realNameCertification" && create_step_ === 100) {
            item.router += "/info"
        }

        history.push(item.router)
    };

    render() {
        const { pathname } = this.props;
        const { userData, userInfo, customerType } = this.state;
        const is_branch_ = StorageUtil.getItem(LocalConst.isBranchClient);
        const applicationStatus = StorageUtil.getItem(LocalConst.applicationStatus) || {};
        const ysClient = StorageUtil.getItem(LocalConst.ysClient);
        const menuList = [
            {
                name: '我的',
                children: [
                    {
                        name: '个人中心',
                        router: '/user'
                    },
                    {
                        name: '通知公告',
                        router: '/notices/list'
                    },
                    {
                        name: '订单信息',
                        router: '/order/message'
                    },
                    {
                        name: '收藏商品',
                        router: '/collect/list'
                    },
                    {
                        name: '购买记录',
                        router: '/keep/list'
                    },
                    // {
                    //     name: '我的银行卡',
                    //     router: '/bank/list'
                    // },
                    {
                        name: '余额',
                        router: '/capital/balance'
                    }
                ]
            },
            {
                name: '订单管理',
                children: [
                    {
                        name: '订货单',
                        router: '/order/list'
                    },
                    {
                        name: '退货单',
                        router: '/refund/list'
                    },
                    {
                        name: '换货单',
                        router: '/exchange/goods/list'
                    },
                    {
                        name: '信用购订单',
                        router: '/credit/order/list'
                    },
                    {
                        name: '延迟付订单',
                        router: '/delay/order/list'
                    },
                ]
            },
            // {
            //     name: '财务管理',
            //     children: [{
            //         name: '对账明细',
            //         router: '/capital/balance'
            //     },]
            // },
            {
                name: '系统设置',
                children: [{
                    name: '帐号信息',
                    router: '/account'
                }, {
                    name: '收货地址',
                    router: '/address'
                }, {
                    name: '开票信息',
                    router: '/invoice'
                },]
            },
        ];

        if (is_branch_) {
            menuList[1].children.push({
                name: '退货单',
                router: '/refund/list'
            })
        }
        if (applicationStatus.application_16_status_) {
            if (userData && userData.client_has_distribution_) {
                menuList[2].children.push({
                    name: '销售中心',
                    router: '/distribution'
                });

                if (!userData.is_client_child_account_ && ysClient.child_account_type_ === 2) {
                    menuList[3].children.push({
                        name: '员工管理',
                        router: '/staff/list'
                    });
                }
            }
        }

        // 信用购
        if (userData.enable_corporation_client_credit_quota_) {
            menuList[0].children.push({
                name: '信用购',
                router: '/creditBuy'
            });
        }

        // 延迟付
        if (userData.enable_provider_client_delay_quota_) {
            menuList[0].children.push({
                name: '延迟付',
                router: '/delayPay'
            });
        }

        // 金品豆
        menuList[0].children.push({
            name: '金品豆',
            router: '/myGoldBean'
        });

        // 交易回单
        menuList[0].children.push({
            name: '交易回单',
            router: '/myTradeReceipt'
        });

        // 实名认证
        //资金帐户类型如果是个人或业务员,不显示实名认证
        //账户类型 1:企业;2:个体工商户;3:个人;4:业务员
        if (userInfo && userInfo.type < 3) {
            menuList[0].children.push({
                name: '实名认证',
                router: '/realNameCertification'
            });
        }

        // // 对私并排除业务员
        // // customerType B对公 C对私  
        if (customerType === "C" && userInfo.type !== 4) {
            menuList[0].children.push({
                name: '我的银行卡',
                router: '/bank/list'
            })
        }



        return (
            <section className="user-menu-box">
                <dl>
                    {
                        menuList.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <dt>{item.name}</dt>
                                    {
                                        item.children.map((subItem, sIndex) => {
                                            const cls = classnames({ 'active': pathname.includes(subItem.router) });
                                            return (
                                                <dd className={cls} role="none" key={sIndex}
                                                    onClick={() => this.toPage(subItem)}>{subItem.name}</dd>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </dl>
            </section>
        )
    }
}

export default UserMenu
