import fetch from 'dva/fetch';
import {Modal, message} from 'antd';
import StorageUtil from './storage-util';
import CommonUtil from "@utils/common-util";
import RenderUtil from "./render-util";
import UrlUtil from '../utils/url-config';



const API = UrlUtil.getAPI();

function parseJSON(response) {
    return response.json();
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error["status-code"] = response.headers.get("status-code");
    error.status = response.status;
    error.response = response;
    throw error;
}

function getSearchFromObject(param) {
    if (!param) return '';
    if (typeof param !== 'object') return '';
    let _search = '?';
    for (const key in param) {
        _search += `${key}=${encodeURIComponent(param[key])}&`;
    }
    return _search.slice(0, -1);
}

function getNonceParam(url) {
    let nonce = new Date().getTime() + '' + Math.floor(Math.random() * 10000);
    if (url.indexOf('?') > -1) {
        url += "&nonce=" + nonce;
    } else {
        url += "?nonce=" + nonce;
    }
    return url;
}


/**
 * 展示系统升级提示
 */
function _showUpgradeTips(){
  if(document.getElementById('upgradeTips')) {
    return
  } else {
    let strVar = "";
      strVar += "<div class=\"full-loading\">\n";
      strVar += "    <div class=\"page-loading-box \">\n";
      strVar += "        <div class=\"page-loading-icon\">\n";
      strVar += "            <i class=\"circle inner\"></i>\n";
      strVar += "            <i class=\"circle outer\"></i>\n";
      strVar += "        </div>\n";
      strVar += "        <p>系统正在升级中(稍等约2分钟)，请勿刷新...</p>\n";
      strVar += "    </div>\n";
      strVar += "</div>\n";
      
    let div = document.createElement('div')
    div.id = "upgradeTips";
    div.innerHTML=strVar;
    document.body.append(div);
    
    window.upgradeTipsInterval = setInterval(() => { // 每3秒检查一次，是否成功
      document.getElementById('upgradeTips') && request('GET', `${API}/v1/shop/home/noAuth/health`, null, function(){
        window.location.reload()
      });
    }, 3000);
    setTimeout(() => { // 5分钟服务器无响应，视为系统错误了
      if(document.getElementById('upgradeTips')){
        setTimeout(() => {
          clearInterval(window.upgradeTipsInterval)
          document.getElementById('upgradeTips').remove();
          alert('❌系统出错了');
        }, 1000);
      }
    }, 1000 * 60 * 5);
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(meth, url, params, success) {

    try {
      if(params){
        /// 对字符串和对象进行单引号过滤
        switch (typeof params) {
          case 'object':
            const params_object = JSON.parse(JSON.stringify(params).replaceAll('\'', ''));
            // console.log('params_object-->', params_object);
            params = params_object;
            break;
          case 'string':
            const params_string = params.replaceAll('\'', '');
            console.log('params_string-->', params_string);
            params = params_string;
            break;
          default:
        }
      }
    } catch(e){
      console.log(e)
    }
    

    let token = StorageUtil.getItem("token", true);
    /**
     * meth === 'body' 兼容实体传参
     * */
    const opts = {
        method: meth.toLowerCase() === 'body' ? 'POST' : meth,
        mode: 'cors',
        headers: {
            'Content-Type': (meth.toLowerCase() === 'get' || meth.toLowerCase() === 'body') ? 'application/json;charset=utf-8' : 'application/x-www-form-urlencoded',
            'yunshl_token': token,
            'token_type': 'PC',
            'app_source': 'PC',
        },
        body: (meth.toLowerCase() === 'get') ? null : (meth.toLowerCase() === 'body' ? JSON.stringify(params) : getSearchFromObject(params).substring(1)),
    };
    if (meth.toLowerCase() === 'get') {
        url += getSearchFromObject(params);
    }
    //加上nonce参数,防止重放
    url = getNonceParam(url);

    return fetch(url, opts)
        .then(checkStatus)
        .then(parseJSON)
        .then(data => {
            success && success(data);
            return data;
        })
        .catch(err => {
          /// 请求失败处理：1网络检查 2网络正常则视为升级中
          if(err && err.message==='Failed to fetch'){
            if(!window.navigator.onLine || window.pisenOffLine){ // 没有网
              if(!window.FailedTipTimer){
                window.FailedTipTimer = setTimeout(() => { window.FailedTipTimer=null }, 1300); // 避免多次弹窗
                Modal.info({title: '提示', content: '请检查网络'});
              }
            } else {
              _showUpgradeTips([meth, url, params, success]);
            }
          }
          
          if(err.status === 400 && !window.alert400) {
            window.alert400 = setTimeout(() => { window.alert400=null }, 1300); // 避免多次弹窗

            console.log('err.response-->',err.response);
            const text = err.response.text()
            console.log('text-->',text);


            Modal.error({
              centered: true,
              title: '输入错误',
              content: text,
              okText: '确定',
              onOk: () => {
                // RenderUtil.renderLoginModal();
              }
            })
          }
          
          if (err.status === 401) {
              let titleTips = '登录过期';
              // @ts-ignore
              let contentTips = '登录信息已经过期，请重新登录';
              // status-code 401.3:下线通知 401.4:登录过期
              if (err['status-code'] === '401.3') {
                  titleTips = '下线通知';
                  // @ts-ignore
                  contentTips = '您的账号已在其他设备登录，请重新登录。 如非本人操作，请及时修改密码';
              }
              CommonUtil.clearAccount();
              /*处理多次执行*/
              Modal.destroyAll();
              Modal.error({
                  centered: true,
                  title: titleTips,
                  content: contentTips,
                  okText: '登录',
                  onOk: () => {
                      RenderUtil.renderLoginModal();
                  }
              })

          }
          if (err.status === 409) {
              // 处理多次执行
              Modal.destroyAll();
              message.destroy();
              Modal.error({
                  title: '当前单据已被修改过，请重新刷新本页后再操作',
                  okText: '刷新',
                  onOk: () => {
                      window.location.reload();
                  }
              });
          }
          if (err.response) {
              err = err.response;
              err.message = err.statusText;
          }

          return err;
        });
}


