const routers = [
    {
        path: '/',
        layoutMax: true,
        component: () => import('./routes/home/home-route'),
        title: '首页'
    },
    {
        path: '/home/:id?',
        layoutMax: true,
        component: () => import('./routes/home/home-route'),
        title: '首页'
    },
    {
        path: '/page/:id', // 404
        layoutMax: true,
        component: () => import('./routes/home/page-route'),
        title: '自定义页面'
    },
    {
        layoutMax: true,
        path: '/goods/list', //商品列表
        // models: () => [import('./models/goods-base-model')], 
        component: () => import('./routes/goods/goods-list-route'),
        title: '商品列表'
    },
    {
        layoutMax: true,
        path: '/goods/exchange/list/:cartId/:discountActId', //商品换购列表
        // models: () => [import('./models/goods-base-model')], goods-exchange-list-route
        component: () => import('./routes/goods/goods-exchange-list-route'),
        title: '商品换购列表'
    },
    {
        path: '/goods/detail/:id/:productId?', //商品详情
        // models: () => [import('./models/goods-base-model')],
        component: () => import('./routes/goods/goods-detail-route'),
        title: '商品详情'
    },
    {
        path: '/cart', //购物车
        models: () => [import('./models/cart/cart-model')],
        component: () => import('./routes/cart/cart-route'),
        title: '购物车'
    },
    {
        path: '/cart/import', //购物车导入
        component: () => import('./routes/cart/cart-import-route'),
        title: '购物车导入'
    },
    {
        path: '/order/create', // 订单确认
        component: () => import('./routes/order/order-create-route'),
        title: '订单确认'
    },
    {
        path: '/order/pay/result/:id?', //支付结果页
        component: () => import('./routes/order/order-pay-result-route'),
        title: '支付结果页'
    },
    {
        path: '/order/pay/:id?',//支付页
        component: () => import('./routes/order/order-pay-route'),
        title: '支付页'
    },
    {
        path: '/order/list', // 订单列表
        showMenu: true,
        models: () => [import('./models/order/order-model')],
        component: () => import('./routes/order/order-list-route.js'),
        title: '订单列表'
    },
    {
        path: '/order/detail/:id', // 订单详情
        models: () => [import('./models/order/order-model')],
        component: () => import('./routes/order/order-detail-route.js'),
        title: '订单详情'
    },
    {
        path: '/order/log', // 订单日志
        component: () => import('./routes/order/order-log-route.js'),
        title: '订单日志'
    },
    {
        path: '/user', // 个人中心
        showMenu: true,
        component: () => import('./routes/user/user-route.js'),
        title: '个人中心'
    },
    {
        path: '/deposit/detail', // 对账明细
        showMenu: true,
        component: () => import('./routes/user/deposit-details-route'),
        title: '对账明细'
    }, 
    {
        path: '/capital/balance', // 余额
        showMenu: true,
        component: () => import('./routes/user/capital-balance-route'),
        title: '余额'
    },
    {
        path: '/account', // 账户信息
        showMenu: true,
        component: () => import('./routes/user/account-route'),
        title: '账户信息'
    },
    {
        path: '/address', // 收货地址
        showMenu: true,
        component: () => import('./routes/user/address-route'),
        title: '收货地址'
    },
    {
        path: '/invoice', // 开票信息
        showMenu: true,
        component: () => import('./routes/user/invoice-route'),
        title: '收货地址'
    },
    {
        path: '/notices/list', // 通知公告列表
        showMenu: true,
        component: () => import('./routes/user/notices-route'),
        title: '通知公告列表'
    },
    {
        path: '/notices/detail/:id', // 通知公告详情
        component: () => import('./routes/user/notices-detail-route'),
        title: '通知公告详情'
    },
    {
        path: '/order/message', // 订单消息
        showMenu: true,
        component: () => import('./routes/user/order-message-route'),
        title: '订单消息'
    },
    {
        path: '/refund/list', // 退货单
        showMenu: true,
        component: () => import('./routes/refund/refund-list-route'),
        title: '退货单'
    },
    {
        path: '/refund/detail/:id', // 退货单详情
        component: () => import('./routes/refund/refund-detail-route'),
        title: '退货单详情'
    },
    {
        path: '/help/detail', // 帮助详情
        component: () => import('./routes/user/help-detail-route'),
        title: '帮助详情'
    },
    {
        path: '/collect/list', // 收藏列表
        component: () => import('./routes/user/collect-list-route'),
        title: '收藏列表'
    },
    {
        path: '/keep/list', // 常用清单
        component: () => import('./routes/user/keep-list-route'),
        title: '常用清单'
    },
    {
        path: '/bank/list', // 我的银行卡
        showMenu: true,
        component: () => import('./routes/user/bankcard-list-route'),
        title: '我的银行卡'
    },
    {
        path: '/join', // 商家入驻
        component: () => import('./routes/other/merchants-settled'),
        title: '商家入驻'
    },
    {
        path: '/exchange/goods/apply', // 换货申请
        component: () => import('./routes/exchange-goods/exchange-goods-apply-route'),
        title: '换货申请',
        showMenu: true,
    },
    {
        path: '/exchange/goods/list', // 换货列表
        component: () => import('./routes/exchange-goods/exchange-goods-list-route'),
        title: '换货列表',
        showMenu: true,
    },
    {
        path: '/exchange/goods/detail/:id', // 换货详情
        component: () => import('./routes/exchange-goods/exchange-goods-detail-route'),
        title: '换货详情',
    },
    {
        path: '/distribution', // 销售中心
        component: () => import('./routes/distribution/distribution-home-route'),
        title: '销售中心',
        showMenu: true,
    },
    {
        path: '/account/log', // 收支明细
        component: () => import('./routes/distribution/account-log-route'),
        title: '收支明细',
        showMenu: true,
    },
    {
        path: '/withdrawal/log', // 提现申请
        component: () => import('./routes/distribution/withdraw-log-route'),
        title: '提现申请',
        showMenu: true,
    },
    {
        title: '员工管理',
        path: '/staff/list', // 员工管理
        showMenu: true,
        component: () => import('./routes/user/staff-route'),
    },
    {
        title: '员工业绩',
        path: '/client/account/performance/:id', // 员工业绩
        showMenu: true,
        component: () => import('./routes/distribution/client-account-performance-route'),
    },
    {
        path: '/smartPurchase', //智能采购
        models: () => [import('./models/smart-purchase/smart-purchase-model')],
        component: () => import('./routes/smart-purchase/smart-purchase-route'),
        title: '智能采购'
    },
    {
        path: '/wechat/pay', // 微信支付
        layoutMax: true,
        component: () => import('./routes/other/wechat-pay'),
    },
    {
        path: '/jd/pay', // 京东支付
        layoutMax: true,
        component: () => import('./routes/other/jd-pay'),
    },
    {
        path: '/scanPay', // 统一扫码支付页
        layoutMax: true,
        component: () => import('./routes/other/scan-pay'),
    },
    {
        path: '/creditBuy', // 信用购
        showMenu: true,
        component: () => import('./routes/user/credit-buy-route'),
    },
    {
        path: '/delayPay', // 延迟付
        showMenu: true,
        component: () => import('./routes/user/delay-pay-route'),
    },
    {
        path: '/myGoldBean', // 金品豆
        showMenu: true,
        component: () => import('./routes/user/my-gold-bean-route'),
    },
    {
        path: '/myTradeReceipt', // 交易回单
        showMenu: true,
        component: () => import('./routes/user/my-trade-receipt-route/my-trade-receipt-route'),
    },
    {
        path: '/realNameCertification', // 实名认证
        showMenu: true,
        component: () => import('./routes/user/real-name-certification'),
    },
    {
        path: '/realNameCertification/info', // 实名认证
        showMenu: true,
        component: () => import('./routes/user/real-name-certification'),
    },
    {
        path: '/credit/order/list', // 信用购订单
        showMenu: true,
        component: () => import('./routes/order/credit-buy-order-list-route.js'),
        title: '信用购订单'
    },
    {
        path: '/delay/order/list', // 延迟付订单
        showMenu: true,
        component: () => import('./routes/order/delay-pay-order-list-route.js'),
        title: '延迟付订单'
    },
    {
        path: '*', // 404
        component: () => import('./routes/other/404-route'),
        title: '404'
    },
];

export default routers;

