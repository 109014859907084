export default class StorageUtil {
    static setItem(key, val, isSession = false) {
        const adapter = isSession ? sessionStorage : localStorage;
        adapter.setItem(key, JSON.stringify(val));
    }

    static getItem(key, isSession = false) {
        const adapter = isSession ? sessionStorage : localStorage;
        return adapter.getItem(key)&&JSON.parse(adapter.getItem(key));
    }

    static delItem(key, isSession = false) {
        const adapter = isSession ? sessionStorage : localStorage;
        adapter.removeItem(key);
    }
}
// 定制setItemEvent事件，用于页面监听localStorage变化
const originalSetItem = localStorage.setItem;
localStorage.setItem = function (key, newValue) {
    const setItemEvent = new Event("setItemEvent");
    setItemEvent.key = key;
    setItemEvent.newValue = newValue;
    window.dispatchEvent(setItemEvent);
    originalSetItem.apply(this, arguments);
};
