import React from 'react';
import "./style.scss";

const SvgIcon = (props) => {
  return <svg aria-hidden="true"
              className={`svg-icon ${props.href} ${props.className || ''}`}
              onClick={props.onClick}>
    <use xlinkHref={`#${props.href}`}/>
  </svg>
};

export default SvgIcon;

