import StorageUtil from "./storage-util";
import {LocalConst} from "./constant";
import CommonUtil from "./common-util";
import {SystemFetch, ShopFetch, CartFetch, GoldBeanFetch} from "../services/service";
import CheckUtil from "./check-util";

const InitUtil = {
    // 获取商城设置
    getShopCompanySetting(companyId) {
        return new Promise((resolve, reject) => {
                SystemFetch.getShopCompanySetting(companyId, rs => {
                    if (rs.status === 1) {
                        const {goodsSetting, houseSetting, saleSetting, shopSetting, distributionRebateSetting, top_goods_type_node_, sceneSettingDto} = rs.data;
                        // 商品设置
                        if (goodsSetting) {
                            // 是否开启起订量
                            StorageUtil.setItem(LocalConst.enableMinCount, goodsSetting.min_count_);
                            // 是否开启限订量
                            StorageUtil.setItem(LocalConst.enableMaxCount, goodsSetting.max_count_);
                            // 是否开启品牌筛选
                            StorageUtil.setItem(LocalConst.enableBrand, goodsSetting.enable_brand_);
                            // 是否开启风格筛选
                            StorageUtil.setItem(LocalConst.enableStyle, goodsSetting.enable_style_);
                            //商品价格精度
                            StorageUtil.setItem(LocalConst.priceScale, goodsSetting.price_scale_ && goodsSetting.price_scale_);
                            //商品数量精度
                            StorageUtil.setItem(LocalConst.countScale, goodsSetting.count_scale_ && goodsSetting.count_scale_);
                        }
                        if (houseSetting) {
                            StorageUtil.setItem(LocalConst.houseSetting, houseSetting)
                        }
                        if (saleSetting) {
                            StorageUtil.setItem(LocalConst.stockShowType, saleSetting.stock_show_type_);
                            // 是否允许超售
                            StorageUtil.setItem(LocalConst.enableStockZero, saleSetting.enable_stock_zero_);

                            // 交货日期是否必填
                            StorageUtil.setItem(LocalConst.enableExpectTime, saleSetting.enable_expect_time_);
                            // 开启下单金额限制
                            StorageUtil.setItem(LocalConst.enableOrderTotal, saleSetting.enable_order_total_);
                            StorageUtil.setItem(LocalConst.orderTotal, saleSetting.order_total_);
                            // 开启特批价
                            StorageUtil.setItem(LocalConst.enableSpecialTotal, saleSetting.enable_special_total_);
                            // 是否可开发票
                            StorageUtil.setItem(LocalConst.enableTax, saleSetting.enable_tax_);
                            StorageUtil.setItem(LocalConst.containTax, saleSetting.contain_tax_);
                            StorageUtil.setItem(LocalConst.generalTax, saleSetting.general_tax_);
                            StorageUtil.setItem(LocalConst.addedTax, saleSetting.added_tax_);
                            StorageUtil.setItem(LocalConst.otherTax, saleSetting.other_tax_);

                            StorageUtil.setItem(LocalConst.orderDefaultUnit, saleSetting.order_default_unit_);
                            StorageUtil.setItem(LocalConst.enableSaleBackOrder, saleSetting.enable_sale_back_order_);
                        }
                        if (shopSetting) {
                            // 商品列表设置
                            StorageUtil.setItem(LocalConst.goodsListSetting, JSON.parse(shopSetting.goods_list_setting_json_));
                            // 商品分类设置
                            StorageUtil.setItem(LocalConst.goodsTypeSetting, JSON.parse(shopSetting.goods_type_setting_json_));

                            StorageUtil.setItem(LocalConst.shopSetting, shopSetting);
                            // 是否允许游客访问
                            StorageUtil.setItem(LocalConst.allowVisitor, shopSetting.allow_visitor_);
                            // 是否允许游客查看价格
                            StorageUtil.setItem(LocalConst.allowClientPrice, shopSetting.allow_client_price_);
                            // 商品列表展示类型
                            StorageUtil.setItem(LocalConst.showGoodsType, shopSetting.show_goods_type_);
                            // 是否显示单位
                            StorageUtil.setItem(LocalConst.showGoodsUnit, shopSetting.show_goods_unit_);
                            // 商城标题
                            StorageUtil.setItem(LocalConst.shopHomeTitle, shopSetting.shop_home_title_);
                            // 显示供应商信息
                            StorageUtil.setItem(LocalConst.showProviderInfo, shopSetting.show_provider_info_);
                        }
                        if (distributionRebateSetting) {
                            StorageUtil.setItem(LocalConst.enableRebateLevel, distributionRebateSetting.enable_rebate_level_);
                            StorageUtil.setItem(LocalConst.distributionModelTitle, distributionRebateSetting.distribution_model_title_);
                            StorageUtil.setItem(LocalConst.distributionJobName, distributionRebateSetting.distribution_job_name_);
                            StorageUtil.setItem(LocalConst.posterBackground, distributionRebateSetting.poster_background_);
                            StorageUtil.setItem(LocalConst.invitationCardBackground, distributionRebateSetting.invitation_card_background_);
                            StorageUtil.setItem(LocalConst.joiningInstructions, distributionRebateSetting.joining_instructions_);
                            StorageUtil.setItem(LocalConst.bindWay, distributionRebateSetting.bind_way_);
                        }
                        if (top_goods_type_node_) {
                            StorageUtil.setItem(LocalConst.topGoodsTypeNode, top_goods_type_node_);
                        }
                        if (sceneSettingDto) {
                            StorageUtil.setItem(LocalConst.enableH5Scene, sceneSettingDto.enable_h5_scene_);
                        } else {
                            StorageUtil.setItem(LocalConst.enableH5Scene, null);
                        }
                        resolve(rs)
                    } else {
                        reject(rs)
                    }
                }, rs => {
                    reject(rs);
                })
            }
        )
            ;
    },
    getCompany(companyId) {
        return new Promise((resolve, reject) => {
            ShopFetch.getCompany(companyId, rs => {
                if (rs.status === 1) {
                    //edi审核处理代码
                    let data = rs.data;
                    delete data.link_address_;
                    delete data.sms_account_;
                    delete data.link_region_;
                    delete data.link_region_ids_;
                    // 公司信息
                    StorageUtil.setItem(LocalConst.companyData, data);
                    // 公司名称
                    StorageUtil.setItem(LocalConst.companyName, rs.data.name_);
                    //设置页面标题
                    document.title = rs.data.name_;
                    // 公司id
                    StorageUtil.setItem(LocalConst.companyId, rs.data.id_);

                    resolve(rs)
                } else {
                    reject(rs)
                }
            }, rs => {
                reject(rs);
            })
        });
    },
    // 获取微信授权信息
    getWechatUserInfo(code, companyId) {
        return new Promise((resolve, reject) => {

            SystemFetch.getWeChatUserInfo({code: code, company_id_: companyId}, rs => {
                const query = CommonUtil.getSearchFromUrl();
                delete query.code;
                if (rs.status === 1) {
                    const {openid, errcode, headimgurl, nickname} = rs.data;
                    if (errcode) {
                        // 1.检查授权获取结果
                        switch (errcode) {
                            case 40001:
                                // Toast.info('微信授权验证已过期', 1, null, false);
                                break;
                            default:
                                // Toast.info('微信授权失败', 1, null, false);
                                break;
                        }
                        console.error('微信授权失败,错误信息', rs.data);
                    } else {
                        const isLogin = CheckUtil.isLogin();
                        if (isLogin) {
                            // A用户已登录
                            SystemFetch.bindOpenId({wechat_openid_: openid, company_id_: companyId}, rs => {
                                if (rs.status === 1) {
                                    StorageUtil.setItem(LocalConst.wechatBind, true);
                                    resolve(rs);
                                } else {
                                    reject(rs)
                                }
                            }, rs => {
                                reject(rs);
                            });
                        } else {
                            // B用户未登录
                            SystemFetch.openIdLogin({wechat_openid_: openid, company_id_: companyId}, rs => {
                                if (rs.status === 1) {
                                    CommonUtil.checkLoginStatus(rs.data.loginStatus);
                                    if (rs.data.loginStatus === 1) {
                                        // 本地保存登录信息
                                        CommonUtil.saveStorageFormLogin(rs.data.loginUser);
                                        /*YSDH-9108 取消reload, User GoodsDetail补充监听*/
                                    } else if (rs.data.loginStatus === 0) {
                                        // 添加到缓存,'我的'页面展示
                                        StorageUtil.setItem(LocalConst.wechatData, {headimgurl, nickname, openid});
                                    } else {
                                        console.log('error user status, to origin login');
                                    }
                                    resolve(rs);
                                } else {
                                    reject(rs)
                                }
                            }, rs => {
                                reject(rs);
                            });
                        }
                    }
                    resolve(rs)
                } else {
                    reject(rs)
                }

            }, rs => {
                reject(rs);
            })
        });
    },
    // 获取注册设置
    getShopRegisterSetting(companyId) {
        return new Promise((resolve, reject) => {
            ShopFetch.getShopRegisterSetting({company_id_: companyId}, rs => {
                if (rs.status === 1) {
                    StorageUtil.setItem(LocalConst.allowClientRegister, rs.data.enable_application_);
                    StorageUtil.setItem('allowClientRegisterAudit', rs.data.audit_);
                    resolve(rs)
                } else {
                    reject(rs)
                }
            })
        })
    },
    // 获取微信设置
    getWechatSettingByCompanyId(companyId) {
        return new Promise((resolve, reject) => {
            SystemFetch.getByCompanyId({company_id_: companyId}).then(rs => {
                if (rs.status === 1) {
                    StorageUtil.setItem('wechatSetting', rs.data);
                    resolve(rs)
                } else {
                    reject(rs)
                }
            })
        })
    },
    // 获取客户信息
    getClientInfo(companyId) {
        return new Promise((resolve, reject) => {
            const ysUser = StorageUtil.getItem(LocalConst.mShop_user, true) || {};
            SystemFetch.getClientById(ysUser.client_id_, rs => {
                if (rs.status === 1) {
                    StorageUtil.setItem(LocalConst.ysClient, rs.data);
                    this.getGoldBeanByClientId(rs.data.id_ || '');
                    resolve(rs)
                } else {
                    reject(rs)
                }
            })
        })
    },
    // 获取用户的金品豆信息
    getGoldBeanByClientId (clientId) {
        GoldBeanFetch.getGoldBeanByClientId(clientId, rs => {
            if (rs.status === 1) {
                StorageUtil.setItem(LocalConst.clientGoldBeanInfo, rs.data);
            }
        });
    },
    // 获取客户信息
    getShopUserInfo(companyId) {
        return new Promise((resolve, reject) => {
            SystemFetch.getShopUserInfoForHome(null, rs => {
                if (rs.status === 1) {
                    StorageUtil.setItem(LocalConst.mShop_user, rs.data, true)
                }
            })
        })
    },
    // 购物车数量
    getCartNumber() {
        CartFetch.sumNumber(null, rs => {
            if (rs.status === 1) {
                StorageUtil.setItem(LocalConst.cartCount, rs.data)
            }
        })
    },
    // 商品设置
    getGoodsSetting(companyId) {
        return new Promise((resolve, reject) => {
            ShopFetch.getGoodsSetting(companyId, (rs) => {
                if (rs.status === 1) {
                    // 品牌数据列表
                    StorageUtil.setItem(LocalConst.brandList, rs.data.brandList);
                    // 风格数据列表
                    StorageUtil.setItem(LocalConst.styleList, rs.data.styleList);

                    StorageUtil.setItem(LocalConst.tagList, rs.data.tagList);
                    resolve(rs);
                } else {
                    reject(rs);
                }
            })
        });
    },
    // 商品分类
    listTypeByCompany(companyId) {
        return new Promise((resolve, reject) => {
            ShopFetch.listTypeByCompany({companyId: companyId}, (rs) => {
                if (rs.status === 1) {
                    StorageUtil.setItem(LocalConst.typeList, rs.data.children || []);
                    resolve(rs);
                } else {
                    reject(rs);
                }
            })
        });
    },
    // 商品属性
    getUsePropertyTree(companyId) {
        return new Promise((resolve, reject) => {
            ShopFetch.getUsePropertyTree({companyId: companyId}, (rs) => {
                if (rs.status === 1) {
                    StorageUtil.setItem(LocalConst.propertyTree, rs.data.children || []);
                    resolve(rs);
                } else {
                    reject(rs);
                }
            })
        });
    },

    getApplicationStatus(companyId) {
        return SystemFetch.getApplicationStatus({company_id_: companyId}, rs => {
            if (rs.status === 1) {
                StorageUtil.setItem(LocalConst.applicationStatus, rs.data);
            }
        })

    },
    getSubsidiaryById(subsidiary_id_) {
        if (subsidiary_id_) {
            return SystemFetch.getSubsidiaryById(subsidiary_id_, rs => {
                if (rs.status === 1) {
                    StorageUtil.setItem(LocalConst.subsidiary, rs.data);
                }
            })
        }
    },
};

export default InitUtil;
