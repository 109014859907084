import React from 'react';

class ImgAdapt extends React.Component {
    imgLoad = () => {
        const {img: targetEle} = this.refs;
        // // 清除设置尺寸
        const {clientHeight: imgHeight, clientWidth: imgWidth} = targetEle;
        const currentSrc = targetEle.currentSrc;
        if (currentSrc.indexOf('/timg.qipeidd.com/') > -1 ||
            currentSrc.indexOf('/img.qipeidd.com/') > -1 ||
            currentSrc.indexOf('/timg.dh688.cn/') > -1 ||
            currentSrc.indexOf('/img.dh688.cn/') > -1 ||
            currentSrc.indexOf('data:image/') === -1) {
            if (imgWidth >= imgHeight) {
                targetEle.setAttribute("width", "100%");
            } else if (imgHeight > imgWidth) {
                targetEle.setAttribute("height", "100%");
            }
        }
    };

    render() {
        const {src, alt} = this.props;
        return (
            <img src={src}
                 ref='img'
                 onLoad={this.imgLoad}
                 alt={alt || ''}/>
        )

    }
}

export default ImgAdapt
